import { ChevronDownIcon } from "@heroicons/react/16/solid";
import { Link } from "react-router-dom";
import { hasAtLeast } from "remeda";
import { useShallow } from "zustand/shallow";

import { PylonSupportForm } from "../constants/urls";
import { logOut } from "../lib/firebase";
import { pb } from "../lib/pb";
import { useAuthStore, useUserIsLoggedIn } from "../stores/auth-store";
import { Button } from "./Button";
import {
  Dropdown,
  DropdownHeader,
  DropdownItem,
  DropdownMenu,
  DropdownSeparator,
  DropdownTrigger,
} from "./DropdownMenu";

function UserDropDown() {
  const [email, name, organizations] = useAuthStore(
    useShallow((state) => [state.email, state.name, state.organizations]),
  );

  return (
    <Dropdown>
      <Button asChild variant="ghost" className="gap-3 pl-3 pr-2.5 font-normal">
        <DropdownTrigger aria-label={`Open ${name} account dropdown`}>
          <span className="min-w-0 truncate">{name}</span>

          <ChevronDownIcon className="size-4 text-grey-60" />
        </DropdownTrigger>
      </Button>

      <DropdownMenu sideOffset={4} align="end">
        <DropdownHeader>
          <div className="-mt-1 pr-4">
            <div className="text-system-m">Hello, {name}</div>
            <div className="text-system-s/5 text-grey-80">{email}</div>
          </div>
        </DropdownHeader>

        <DropdownSeparator />

        {/* @TODO, add org-less account settings */}
        {hasAtLeast(organizations, 1) && (
          <DropdownItem asChild>
            <Link to={pb.account()}>Account settings</Link>
          </DropdownItem>
        )}

        <DropdownItem onSelect={() => logOut()}>Sign out</DropdownItem>

        <DropdownSeparator />

        <DropdownItem asChild>
          <a href={PylonSupportForm} target="_blank">
            Contact support
          </a>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export function UserDropdownManager() {
  const isUserLoggedIn = useUserIsLoggedIn();

  return isUserLoggedIn ? (
    <UserDropDown />
  ) : (
    <div className="flex items-center gap-3">
      <p className="text-system-m text-grey-80">Already have an account?</p>

      <Button asChild variant="ghost">
        <Link to="/login">{"Sign in"}</Link>
      </Button>
    </div>
  );
}
