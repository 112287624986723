import { ArrowUpRightIcon } from "@heroicons/react/16/solid";
import clsx from "clsx";
import { motion } from "motion/react";
import type { NavLinkProps } from "react-router-dom";
import { NavLink } from "react-router-dom";

import { cn } from "../utils/twx";

interface SidebarNavLinkProps extends NavLinkProps {
  children: React.ReactNode;
}

export default function SidebarNavLink({
  to,
  children,
  ...rest
}: SidebarNavLinkProps) {
  return (
    <NavLink
      className={({ isActive }) =>
        clsx(
          isActive ? "text-black" : "text-grey-80",
          "relative flex px-5 py-1 text-system-m-plus hover:text-black",
        )
      }
      to={to}
      {...rest}
    >
      {({ isActive }) => (
        <>
          {isActive ? (
            <motion.div
              layoutId="activeIndicator"
              className="absolute inset-y-1 left-0 w-[0.1rem] rounded-r-full bg-black"
            />
          ) : null}

          <span>{children}</span>
        </>
      )}
    </NavLink>
  );
}

interface SidebarNavButtonProps
  extends React.ComponentPropsWithoutRef<"button"> {
  isActive: boolean;
}

export function SidebarNavButton({
  isActive,
  children,
  ...rest
}: SidebarNavButtonProps) {
  return (
    <button
      className={clsx(
        isActive ? "text-black" : "text-grey-80",
        "relative flex px-5 py-1 text-system-m-plus hover:text-black",
      )}
      {...rest}
    >
      {isActive ? (
        <motion.div
          layoutId="activeIndicator"
          className="absolute inset-y-1 left-0 w-[0.1rem] rounded-r-full bg-black"
        />
      ) : null}

      <span>{children}</span>
    </button>
  );
}

export function SidebarNavExternalLink({
  href,
  children,
}: {
  href: string | undefined;
  children: React.ReactNode;
}) {
  return (
    <a
      className="relative flex items-center gap-0.5 px-5 py-1 text-system-m-plus text-grey-80 hover:text-black"
      href={href}
      target="_blank"
    >
      <span>{children}</span>

      <ArrowUpRightIcon className="size-4" />
    </a>
  );
}

export function SidebarNavLinkWrapper({
  children,
  className = "",
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <nav
      className={cn(
        "flex flex-col gap-2 self-start rounded-xl border-0.5 bg-white py-3",
        className,
      )}
    >
      {children}
    </nav>
  );
}
