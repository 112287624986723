import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useEffect } from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
import { Toaster } from "sonner";

import { AuthContextProvider } from "../context/auth-context.js";
import { useTitle } from "../hooks/use-title.js";
import { queryClient } from "../lib/query/client";
import { VercelAnalytics } from "../lib/vercel";

export default function RootLayout() {
  const title = useTitle();
  useEffect(() => {
    document.title = title ? `${title} | Conduit` : "Conduit";
  }, [title]);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <Outlet />

        <Toaster />

        <ScrollRestoration />

        <VercelAnalytics />
      </AuthContextProvider>

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
